* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Inter', sans-serif;
}


body {
  background: #23252C;
}


.container {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  

  background-color: #1A1B21;
  text-align: center;
  width: 317px;
  border-radius: 11px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}

.profile-image {
  display: flex;
  width: 317px;
  height: 317px;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;

}


a {

  margin: 0;
  padding:0;
  height: 25px;
  padding: 20px 0;
  text-decoration: none;

}

a:visited {
  color: #F5F5F5;

}

.info-ctn {
  color: #FFFFFF;


}

h1 {
  margin: 21px 0 6px 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
}

h5 {
  margin: 0;
  margin-bottom: 9px;
  color: #F3BF99;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12.8px;
  line-height: 15px;
}

h6 {
  margin: 0;
  color: #F5F5F5;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10.24px;
  line-height: 150%;
}

.website-link {

padding: 0;

}

.btn-container {
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding:  15px 35px 33px 35px;

}



.link-btn {
    padding: 0;

}



button {
  width: 115px;
  height: 34px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 13px 9px 11px;
  gap: 8px;
  background: #FFFFFF;
  color: #374151;
  border: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.btn-linkedin {
  color: #FFFFFF;
  border: none;
  background: #5093E2;
}

.btn-linkedin:hover {

    background-color: #0d42a3;
    color: #FFFFFF;
    cursor: pointer;

}

.btn-email:hover {
  background-color: darkgrey;
  color: #FFFFFF;
  cursor: pointer;


}

h2 {
  margin: 0;
  padding: 0 0 5px 0 ;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%; 
}

p {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10.24px;
  line-height: 150%;
}


.about-ctn {
  color: #DCDCDC;
  text-align: left;
  padding: 0 35px;

}

.about-text {
  padding-bottom: 15px;

}

.interests-ctn {
  color: #DCDCDC;
  text-align: left;
  padding: 0 35px;


}

.interests-text {
  padding-bottom: 40px;

}

.footer-ctn {
  background-color: #161619;;
  height: 64px;
  padding: 0 73px;
  display: flex;
  justify-content: space-between;
  border-bottom-right-radius: 11px;
  border-bottom-left-radius: 11px;

}




